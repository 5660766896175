var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticStyle:{"justify-content":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10","lg":"10"}},[_c('h2',[_vm._v("Wijzig wachtwoord")]),_c('p',[_vm._v(" Op deze pagina kunt u uw wachtwoord aanpassen. Uw nieuwe wachtwoord moet minstens 8 karakters bevatten. ")]),_c('v-divider',{staticClass:"mb-4"}),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"E-mail adres","outlined":"","disabled":"","value":this.$store.state.user.email,"id":"mailAddress","required":"","type":"text","autocomplete":"username"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"Nieuw wachtwoord","outlined":"","id":"newPassword","required":"","type":"password","autocomplete":"new-password","rules":[
                function (v) { return !!v || 'Wachtwoord moet zijn ingevuld.'; },
                function (v) { return (v && v.length >= 8) ||
                  'Wachtwoord moet minstens 8 karakters lang zijn.'; } ]},model:{value:(_vm.newPassword.newPassword),callback:function ($$v) {_vm.$set(_vm.newPassword, "newPassword", $$v)},expression:"newPassword.newPassword"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"Herhaal nieuw wachtwoord","outlined":"","id":"repeatNewPassword","required":"","type":"password","autocomplete":"new-password","rules":[
                function (v) { return !!v || 'Wachtwoordherhaling moet zijn ingevuld.'; },
                this.newPassword.newPassword ===
                  this.newPassword.repeatNewPassword ||
                  'Wachtwoord en wachtwoordherhaling moeten overeenkomen.' ]},model:{value:(_vm.newPassword.repeatNewPassword),callback:function ($$v) {_vm.$set(_vm.newPassword, "repeatNewPassword", $$v)},expression:"newPassword.repeatNewPassword"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"12"}},[_c('v-btn',{attrs:{"color":"accent","elevation":"1","block":"","large":"","id":"newPasswordSubmit","disabled":!_vm.isFormValid},on:{"click":_vm.UpdatePassword}},[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v(" mdi-lock-reset ")]),_vm._v(" Wijzig wachtwoord")],1)],1)],1)],1),(this.state === 'loaded')?[_c('div',{staticClass:"alert succes"},[_c('p',[_c('strong',[_vm._v("Wachtwoord gewijzigd.")])]),_c('p',[_vm._v("U kunt vanaf nu inloggen met uw nieuwe wachtwoord.")])])]:_vm._e(),_c('br'),(this.error)?[_c('div',{staticClass:"alert error"},[_vm._v(" Er is iets mis gegaan: "),_c('pre',[_vm._v(" "+_vm._s(this.detail.data.detail)+" ")])])]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }